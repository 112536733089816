import {Component, Input, OnInit} from '@angular/core';
import {AngularFireStorage} from "@angular/fire/storage";
import {ArticlesService} from "../../services/articles.service";
import {EditingService} from "../../services/editing.service";
import {ModalService} from "../../shared/modal/modal.service";
import {BlogpostComponent} from "../../shared/forms/blogpost/blogpost.component";

@Component({
  selector: 'app-blog-list-item',
  templateUrl: './blog-list-item.component.html',
  styleUrls: ['./blog-list-item.component.scss']
})
export class BlogListItemComponent implements OnInit {
  type;
  editMode;
  subscriptions = [];
  image;
  @Input() article;
  constructor(
      private modal: ModalService,
      private storage: AngularFireStorage,
      private articleService: ArticlesService,
      private editService: EditingService,
  ) {
    this.subscriptions.push(this.editService.editMode.subscribe( data => {
          this.editMode = data;
        }
    ));
  }

  ngOnInit() {
    this.type = this.article.type;
    this.getImage();
  }
  getImage() {
    if (this.article.attributes.image != null) {
      const ref = this.storage.ref(this.article.attributes.image);
      this.image = ref.getDownloadURL();
      console.log(`image url ${this.image}`, this.image);
    } else if (this.article.attributes.fileLink != null) {
      this.image = this.article.attributes.fileLink;
    }
  }
  deleteArticle() {
    this.articleService.deleteArticle({type: this.type, id: this.article.id});
  }
  editArticle() {
    this.modal.open(BlogpostComponent, { data: {type: this.type, title: 'Edit Post', content: this.article }}).afterClosed.subscribe(response => {
      //console.log('edit response' , response);
      this.articleService.saveArticles(response);
    });
  }
}
