import { Component, OnInit, OnChanges } from '@angular/core';
import {ModalRef} from "../../modal/modal-ref";
import {ModalConfig} from "../../modal/modal-config";
import {AngularFireStorage} from "@angular/fire/storage";
import {Ng2ImgMaxService} from "ng2-img-max";
import {NgForm} from "@angular/forms";
import firebase from "firebase";

@Component({
  selector: 'app-add-gallery',
  templateUrl: './add-gallery.component.html',
  styleUrls: ['./add-gallery.component.scss']
})
export class AddGalleryComponent implements OnInit {

  /**
   * input data from the modal, this is set from the config
   */
  public inputData;

  base64Image: string;
  image;
  uploadedImage: File;
  images = [];
  imagesHolder = [];
  selectedImages = new Set();
  imagesToSave = new Set();
  public formData = {title: '', content: '', selectedImages: [], bg: '', fileLink: '', imageIsFile: true, gallery: true, order: 0};

  private id;

  constructor(
      private modal: ModalRef,
      public config: ModalConfig,
      private storage: AngularFireStorage,
      private ng2ImgMax: Ng2ImgMaxService
  ) {
    this.inputData = this.config.data;
  }

  ngOnInit() {
    //this.image = this.inputData.content.attributes.image;
    this.getSavedImages();
    if (this.inputData.content.id) {
      this.formData = this.inputData.content.attributes;
      this.id = this.inputData.content.id;
    }
  }

  ngOnChanges(changes) {
    console.log(changes)
  }

  addImage(event) {
    const file = event.target.files[0];
    const filePath = 'user-images/' + file.name;

    this.ng2ImgMax.resizeImage(file, 720, 500).subscribe(
        result => {
          this.uploadedImage = new File([result], result.name);
          const task = this.storage.upload(filePath, this.uploadedImage).then((image) => {
            console.log(image);
            // this.selectedImages.add(image.metadata.fullPath);
            const imageRef = this.storage.ref(image.metadata.fullPath);
            const imagePath = imageRef.getDownloadURL();
            const imageObject = {path: image.metadata.fullPath, imageDownloadPath: imagePath};
            this.selectedImages.add(imageObject);
            this.getSavedImages();
          });
        }
    )


    // this.image = filePath;

  }

  /**
   * Passes the form data out of the modal through the on close method,
   * Only basic validation atm, needs to be extended
   *
   * The type of page is passed through so that the correct api call can be made
   * @param formData
   */

  onAddArticle(formData: NgForm) {
    let sendImages = [];
    this.selectedImages.forEach((value) => {
      console.log(value);
      // @ts-ignore
      sendImages.push(value.path);
    });

    const formAttributes = formData.form.value;
    formAttributes.images = Array.from(sendImages);
    formAttributes.gallery = true;
    const data = {type: this.inputData.type, attributes: formAttributes, id: this.id};
    this.modal.close(data);
  }

  getSavedImages() {
    const storageRef = firebase.storage().ref();
    const listRef = storageRef.child('user-images');
    console.log(this.inputData);
    this.images = []
    listRef.listAll().then((res) => {
      res.items.forEach((itemRef) => {
        const imageRef = this.storage.ref(itemRef.fullPath);
        const imagePath = imageRef.getDownloadURL();
        const imageObject = {path: itemRef.fullPath, imageDownloadPath: imagePath};
        this.images.push(imageObject);
        if (this.inputData.content.attributes !== undefined){
          if (this.inputData.content.attributes.images !== undefined && this.inputData.content.attributes.images.includes(itemRef.fullPath)) {
            this.selectedImages.add(imageObject);
          }
        }
      });
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
    console.log(this.images);
  }

  selectImage(img) {
    console.log(`select`,img);
    this.selectedImages.add(img);
    this.imagesToSave.add(img.path);

  }
  removeImage(id) {
    console.log('removeImage', id);
    this.selectedImages.delete(id);
  }
}
