import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import {AppRoutingModule} from '../app-routing.module';
import { AnimatedArticleImagedComponent } from './animated-article-imaged/animated-article-imaged.component';
import { AnimatedArticleComponent } from './animated-article/animated-article.component';
import {ModalModule} from './modal/modal.module';
import { FormAddArticleComponent } from './forms/form-add-article/form-add-article.component';
import {ModalService} from "./modal/modal.service";
import {FormsModule} from "@angular/forms";
import { AnimatedArticleLeftComponent } from './animated-article-left/animated-article-left.component';
import { AnimatedArticleRightComponent } from './animated-article-right/animated-article-right.component';
import { LoginComponent } from './forms/login/login.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { TitleFormComponent } from './forms/title-form/title-form.component';
import {Ng2ImgMaxModule} from "ng2-img-max";
import { GalleryArticleComponent } from './gallery-article/gallery-article.component';
import { AddGalleryComponent } from './forms/add-gallery/add-gallery.component';
import { GalleryComponent } from './gallery/gallery.component';
import { BlogpostComponent } from './forms/blogpost/blogpost.component';
import {QuillModule} from "ngx-quill";
import { AddAdvertComponent } from './forms/advert/advert.component';
import {AdvertComponent} from "./advert/advert.component";




@NgModule({
  declarations: [NavComponent,
    FooterComponent,
    AnimatedArticleImagedComponent,
    AnimatedArticleComponent,
    FormAddArticleComponent,
    AnimatedArticleLeftComponent,
    AnimatedArticleRightComponent,
    LoginComponent,
    PageTitleComponent,
    TitleFormComponent,
    GalleryArticleComponent,
    AddGalleryComponent,
    GalleryComponent,
    BlogpostComponent,
    AddAdvertComponent,
      AdvertComponent
  ],
    imports: [
        CommonModule,
        AppRoutingModule,
        ModalModule,
        FormsModule,
        Ng2ImgMaxModule,
        QuillModule
    ],
  exports: [
    NavComponent,
    FooterComponent,
    PageTitleComponent,
    AnimatedArticleLeftComponent,
    AnimatedArticleRightComponent,
    ModalModule,
    GalleryArticleComponent,
    AdvertComponent,
    GalleryComponent
  ],
  providers: [ModalService],
  entryComponents: [FormAddArticleComponent, TitleFormComponent, AddGalleryComponent, AddAdvertComponent]
})
export class SharedModule { }
