import { Component, OnInit } from '@angular/core';
import {ArticlesService} from "../../services/articles.service";
import {ApiObject} from "../../services/api-calls.service";

@Component({
  selector: 'app-home-feed',
  templateUrl: './home-feed.component.html',
  styleUrls: ['./home-feed.component.scss']
})
export class HomeFeedComponent implements OnInit {
  subscriptions = [];
  articles: ApiObject[];
  type = 'blog';
  currentPost = 0;
  constructor(
      private articleService: ArticlesService,
  ) {
    this.getArticles();
  }

  ngOnInit() {
  }
  getArticles() {
    // @ts-ignore
    this.subscriptions.push( this.articleService.getBlogPosts(this.type).subscribe( data => {

          this.articles = data.reverse();
          this.blogPostTimer();
        })
    );
  }

  blogPostTimer() {
    setInterval(() => {
      if (this.currentPost < this.articles.length) {
        this.currentPost++;
      } else {
        this.currentPost = 0;
      }
    }, 5000);
  }
}
