import { Component, OnInit } from '@angular/core';
import {ModalRef} from "../../modal/modal-ref";
import {ModalConfig} from "../../modal/modal-config";
import {AngularFireStorage} from "@angular/fire/storage";
import {Ng2ImgMaxService} from "ng2-img-max";
import {NgForm} from "@angular/forms";
import firebase from "firebase";
import BlotFormatter from "quill-blot-formatter";
import Quill from "quill";
@Component({
  selector: 'app-blogpost',
  templateUrl: './blogpost.component.html',
  styleUrls: ['./blogpost.component.scss']
})
export class BlogpostComponent implements OnInit {

  modules = {};
  public inputData;
  content;
  base64Image: string;
  image;
  uploadedImage: File;
  images = [];
  public formData = { title: '', date: '', summary: '', content: '', image: '', bg: '', fileLink: '', imageIsFile: true, picture_text: '', gallery: false, order: 0};

  private id;
  constructor(
      private modal: ModalRef,
      public config: ModalConfig,
      private storage: AngularFireStorage,
      private ng2ImgMax: Ng2ImgMaxService
  ) {
    this.inputData = this.config.data;
    this.modules = {
      blotFormatter: {}
    };
  }

  ngOnInit() {
    console.log('form input' , this.inputData);
    this.image = this.inputData.content.attributes.image !== undefined ? this.inputData.content.attributes.image :  '';
    this.getSavedImages();
    if (this.inputData.content.id) {
      this.formData = this.inputData.content.attributes;
      this.id = this.inputData.content.id;
      this.content = this.inputData.content.attributes.content;
    }
  }

  changedEditor(event) {
    // tslint:disable-next-line:no-console
    console.log('editor-change', event);
    if (event.event === 'text-change') {
      this.content = event.html;
    }
  }
  addImage(event) {
    const file = event.target.files[0];
    const filePath = 'user-images/' + file.name;

    this.ng2ImgMax.resizeImage(file, 720, 500).subscribe(
        result => {
          this.uploadedImage = new File([result], result.name);
          const task = this.storage.upload(filePath, this.uploadedImage).then(() => {
            this.getSavedImages();
            this.image = filePath;
          });
        }
    );
  }

  /**
   * Passes the form data out of the modal through the on close method,
   * Only basic validation atm, needs to be extended
   *
   * The type of page is passed through so that the correct api call can be made
   * @param formData
   */

  onAddArticle(formData: NgForm) {
    const formAttributes = formData.form.value;
    formAttributes.image = this.image;
    formAttributes.gallery = false;
    formAttributes.content = this.content;
    const data = {type: this.inputData.type, attributes: formAttributes, id: this.id};
    console.log('data', data, 'content', this.content);
    this.modal.close(data);
  }

  getSavedImages() {
    this.images = [];
    const storageRef = firebase.storage().ref();
    const listRef = storageRef.child('user-images');
    listRef.listAll().then((res) => {
      res.items.forEach((itemRef) => {
        //console.log(itemRef.fullPath);
        const imageRef = this.storage.ref(itemRef.fullPath);
        const imagePath = imageRef.getDownloadURL();
        const imageObject = {path: itemRef.fullPath, imageDownloadPath: imagePath };
        this.images.push(imageObject);
      });
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
    console.log(this.images);
  }

  selectImage(imagePath) {
    console.log(`select ${imagePath}, current image is ${this.image}`);
    this.image = imagePath;
  }
}
