import { Component, OnInit, OnDestroy, OnChanges} from '@angular/core';
import {ArticlesService} from "../services/articles.service";
import {ModalService} from "../shared/modal/modal.service";
import {EditingService} from "../services/editing.service";
import {TitleServiceService} from "../services/title-service.service";
import {FormAddArticleComponent} from "../shared/forms/form-add-article/form-add-article.component";
import {AddGalleryComponent} from "../shared/forms/add-gallery/add-gallery.component";
import {TitleFormComponent} from "../shared/forms/title-form/title-form.component";
import {ApiObject} from "../services/api-calls.service";
import {AddAdvertComponent} from "../shared/forms/advert/advert.component";
import {AdvertComponent} from "../shared/advert/advert.component";

@Component({
  selector: 'app-for-sale',
  templateUrl: './for-sale.component.html',
  styleUrls: ['./for-sale.component.scss']
})
export class ForSaleComponent implements OnInit {
  editMode;
  /**
   * The articles to be used on the page.
   */
  articles: ApiObject[];

  /**
   * sets the type of article for the page, so that the correct url is used.
   * @type {string}
   */
  type = 'for-sale';

  titleContent;

  subscriptions = [];
  constructor(
      private articleService: ArticlesService,
      private modal: ModalService,
      private editService: EditingService,
      private titleService: TitleServiceService
  ) {
    this.getArticles();
    this.getTitle();
    this.subscriptions.push(this.editService.editMode.subscribe(data => {
      this.editMode = data;
    }));
  }

  ngOnInit() {
  }

  ngOnChanges() {

  }
  ngOnDestroy() {
    this.subscriptions.map((sub) => sub.unsubscribe());
  }

  onAddItem() {
    this.modal.open(FormAddArticleComponent, {data: {type: this.type, title: 'Create Article on page' + this.type}})
        .afterClosed.subscribe( response => {
      this.articleService.saveArticles(response);
      this.getArticles();
    });
  }
  onAddAdvert() {
    this.modal.open(AddGalleryComponent, {data: {type: this.type, title: 'Create Advert on page' + this.type, content: {}}})
        .afterClosed.subscribe( response => {
      this.articleService.saveArticles(response);
      this.getArticles();
    });
  }
  getArticles() {
    this.subscriptions.push( this.articleService.getArticles(this.type).subscribe( data => {

          this.articles = data;
          //     .sort((a, b) => {
          //   return a.attributes.order - b.attributes.order;
          // });
        })
    );
  }
  onAddTitle() {
    this.modal.open(TitleFormComponent, {data: { title: `Create title for  ${this.type}`, page: this.type}}).afterClosed.subscribe(
        response => {
          this.titleService.saveTitle(response);
          this.getTitle();
        }
    );
  }
  getTitle() {
    this.subscriptions.push(this.articleService.getTitle().subscribe(data => {
      const filteredTitle = data.filter((title) => {
        return title.attributes.page === this.type;
      });
      this.titleContent = filteredTitle[0];
      console.log('title content', this.titleContent);
    }));
  }
}
