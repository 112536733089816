import { Component, OnInit } from '@angular/core';
import {ArticlesService} from "../../services/articles.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {AngularFireStorage} from "@angular/fire/storage";
@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss']
})
export class BlogPageComponent implements OnInit {
  subscriptions = [];
  image;
  post;
  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private articleService: ArticlesService,
      private storage: AngularFireStorage,
  ) { }

  ngOnInit() {
    this.getBlogPost();
  }

  getBlogPost() {
    let id = this.route.snapshot.paramMap.get('id');
     this.subscriptions.push( this.articleService.getBlogPost(id).subscribe(data => {
       console.log(data);
       this.post = data;
       this.getImage();
     }));

  }
  getImage() {
    if (this.post.attributes.image != null) {
      const ref = this.storage.ref(this.post.attributes.image);
      this.image = ref.getDownloadURL();
    } else if (this.post.attributes.fileLink != null) {
      this.image = this.post.attributes.fileLink;
    }
  }
}
