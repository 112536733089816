import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from "../modal/modal.service";
import {ArticlesService} from "../../services/articles.service";
import {EditingService} from "../../services/editing.service";
import {AngularFireStorage} from "@angular/fire/storage";
import {AddGalleryComponent} from "../forms/add-gallery/add-gallery.component";
import firebase from "firebase";
import {AddAdvertComponent} from "../forms/advert/advert.component";

@Component({
  selector: 'app-advert',
  templateUrl: './advert.component.html',
  styleUrls: ['./advert.component.scss']
})
export class AdvertComponent implements OnInit {

  type;
  editMode;
  subscriptions = [];
  images = [];
  openImage = '';
  imagesHolder = [];
  imageUrl;
  @Input() article;
  constructor(
      private modal: ModalService,
      private articleService: ArticlesService,
      private editService: EditingService,
      private storage: AngularFireStorage,
  ) {
    this.getImages();
    this.subscriptions.push(this.editService.editMode.subscribe( data => {
          this.editMode = data;
        }
    ));

  }
  editArticle() {
    this.modal.open(AddGalleryComponent, {data: {title: 'Edit Advert', content: this.article, type: this.type}})
        .afterClosed.subscribe( response => {
      this.articleService.saveArticles(response);
    });
  }

  deleteArticle() {
    this.articleService.deleteArticle({type: this.type, id: this.article.id});
  }
  ngOnInit() {
    this.type = this.article.type;

  }

  getImages() {
    const storageRef = firebase.storage().ref();
    const listRef = storageRef.child('user-images');
    listRef.listAll().then((res) => {
      res.items.forEach((itemRef) => {

        const imageRef = this.storage.ref(itemRef.fullPath);
        const imagePath = imageRef.getDownloadURL();
        const imageObject = {path: itemRef.fullPath, imageDownloadPath: imagePath};
        if (this.article.attributes.images.includes(itemRef.fullPath)) {
          this.imagesHolder.push(imageObject);
        }
      });
      this.article.attributes.images.forEach((image) => {
        this.imagesHolder.forEach((item) => {
          if (item.path === image) {
            this.images.push(item);
          }
        });
      });
    }).catch((error) => {
      console.log(error);
      // Uh-oh, an error occurred!
    });

    console.log(this.images);
  }
  toggleFullScreen(image) {

    this.openImage = this.openImage === image ? '' : image;
  }

}
