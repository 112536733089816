import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import { map } from 'rxjs/operators';
import {AngularFireAuth} from "@angular/fire/auth";

export interface ApiObject {
  id: string;
  type: string;
  attributes: object;
}
export interface SettingsObject {
  published: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiCallsService {

  constructor(
      private http: HttpClient,
      private afAuth: AngularFireAuth
  ) { }


  /**
   * Sets any data to the firebase server based on they type of article sent
   * @param data
   * @returns {Observable<Object>}
   */
  setData(data) {
      let dataToSend = data;
      dataToSend.uid = this.afAuth.auth.currentUser.uid;

    return this.http.post<ApiObject>(environment.api + data.type + '.json', dataToSend, {headers: {uid: this.afAuth.auth.currentUser.uid }});
  }

  updateData(data) {
    let dataToSubmit = {[data.id]: data};
    // @ts-ignore
      //dataToSubmit.uid = this.afAuth.auth.currentUser.uid;
    return this.http.patch(environment.api + data.type + '.json', dataToSubmit, { headers: {uid: this.afAuth.auth.currentUser.uid }});
  }

  deleteData(data) {
    return this.http.delete(environment.api + data.type + '/' + data.id + '.json/', { headers: {uid: this.afAuth.auth.currentUser.uid }});
  }

  /**
   * Gets any data requested from the firebase server based on the type given
   * @param data
   * @returns {Observable<Object>}
   */
  getData(data) {
    return this.http.get<ApiObject>(environment.api + data.type + '.json').pipe(
      map(response => {
        const articleArray = [];
        for (const key in  response) {
          if (response.hasOwnProperty(key)) {
            articleArray.push({...response[key], id: key});
          }
        }
        return articleArray;
      })
    );
  }
  getItem(id) {
    return this.http.get(environment.api + 'blog.json').pipe(
        map(response => {
          let post;
          for (const key in  response) {
            if (response.hasOwnProperty(key)) {
             if (key == id) {
               post = response[key];
             }
            }
          }
          return post;
        })
    );
  }

  getSettings() {
    return this.http.get<SettingsObject>(environment.api + 'settings.json');
  }

  setPublished() {
    return this.http.patch(environment.api + 'settings.json', {published: true}, {headers: {uid: this.afAuth.auth.currentUser.uid }} );
  }

  setUnPublished() {
    return this.http.patch(environment.api + 'settings.json', {published: false}, {headers: {uid: this.afAuth.auth.currentUser.uid }});
  }
}
