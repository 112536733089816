import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiObject} from "../../services/api-calls.service";
import {ArticlesService} from "../../services/articles.service";
import {ModalService} from "../../shared/modal/modal.service";
import {EditingService} from "../../services/editing.service";
import {TitleServiceService} from "../../services/title-service.service";
import {FormAddArticleComponent} from "../../shared/forms/form-add-article/form-add-article.component";
import {BlogpostComponent} from "../../shared/forms/blogpost/blogpost.component";

@Component({
  selector: 'app-blog-feed',
  templateUrl: './blog-feed.component.html',
  styleUrls: ['./blog-feed.component.scss']
})
export class BlogFeedComponent implements OnInit, OnDestroy {
  editMode;
  /**
   * The articles to be used on the page.
   */
  articles: ApiObject[];

  /**
   * sets the type of article for the page, so that the correct url is used.
   * @type {string}
   */
  type = 'blog';

  titleContent;

  subscriptions = [];

  constructor(
      private articleService: ArticlesService,
      private modal: ModalService,
      private editService: EditingService,
      private titleService: TitleServiceService
  ) {
    this.getArticles();
    this.subscriptions.push(this.editService.editMode.subscribe(data => {
      this.editMode = data;
    }));
  }
  ngOnInit() {
  }
  ngOnDestroy(): void {
    this.subscriptions.map((sub) => sub.unsubscribe());
  }

  /**
   * opens the modal for creating articles on the page
   * This is returned from the after close area of the modal.
   */
  onAddItem() {
    this.modal.open(BlogpostComponent, {data: {type: this.type, title: 'Create Article on page' + this.type}})
        .afterClosed.subscribe( response => {
      this.articleService.saveArticles(response);
      this.getArticles();
    });
  }
  getArticles() {
    this.subscriptions.push( this.articleService.getArticles(this.type).subscribe( data => {

          this.articles = data.reverse();
        })
    );
  }
}
