import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {PolishingComponent} from './polishing/polishing/polishing.component';
import {RestorationsComponent} from './restorations/restorations/restorations.component';
import {RebuildsComponent} from './rebuilds/rebuilds.component';
import {AboutComponent} from './about/about.component';
import {AdminComponent} from './admin/admin/admin.component';
import {UnderConstructionComponent} from "./under-construction/under-construction.component";
import {TuningComponent} from "./tuning/tuning.component";
import {HireComponent} from "./hire/hire.component";
import {BlogFeedComponent} from "./blog/blog-feed/blog-feed.component";
import {BlogPageComponent} from "./blog/blog-page/blog-page.component";
import {ForSaleComponent} from "./for-sale/for-sale.component";


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'admin', component: AdminComponent
  },
  { path: 'waiting', component: UnderConstructionComponent
  },
  {
    path: 'case-finishing', component: PolishingComponent
  },
  {
    path: 'restorations', component: RestorationsComponent
  },
  {
    path: 'rebuilds', component: RebuildsComponent
  },
  {
    path: 'about', component: AboutComponent
  },
  {
    path : 'tuning', component: TuningComponent
  },
  {
    path : 'hire', component: HireComponent
  },
  {
    path: 'for-sale', component: ForSaleComponent
  },
  {
    path: 'news', component: BlogFeedComponent
  },
  {
    path: 'news/:id', component: BlogPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
